.highlight {
    color: $secondary;
}

.btn {
    background: $primary;
    border: none;
    color: $light;
    cursor: pointer;
    display: inline-block;
    padding: .7rem 2rem;
    text-decoration: none;
    transition: 300ms all ease-in-out;
    border-radius: $base-radius*2;
    text-align: center; 
    @extend .margin-bottom;
    @include breakpoint(giant) {
        padding: .7rem 4rem;
    }

    &:hover, &:active, &:focus {
        background: $secondary;
        color:$light;
    }

    *[class^="icon"] {
        margin-left: .5rem;
        vertical-align: middle;
    }
}

.shadow {
    box-shadow: $base-shadow
}
.noWrap {
    white-space: nowrap;
}

.before-after {
    display: block;
    content: '';
}

.border {
    border-top: solid rem(5px) $secondary;

    @include breakpoint(giant) {
        border-top: solid rem(10px) $secondary;
    }
}
.mB-medium {
    @include breakpoint(small,max) {
        margin-bottom: 1.5rem;
    }
}

.clear {
    clear: both;
    float: none;
}

.clearfix {
    @include pie-clearfix;
}

.hidden {
    display: none;
}

.hide-text {
    @include hide-text();
    display: inline-block;
    overflow: hidden;
    width: 0;
}

.float-left {
    float: left;
}

img.float-left {
    margin-right: 1rem;
}

.float-right {
    float: right;
}

img.float-right {
    margin-left: 1rem;
}

.fluid {
    height: auto;
    width: 100%;
}

.non-fluid {
    width: auto!important;
}

.align-left {
    text-align: left;
}

.align-right {
    text-align: right;
}

.align-center {
    text-align: center;
}

.wide {
    width: 100%;
    display: block;
}

.notification {
    padding: 1em;
    background: $alert;
    color: $light;
    font-size: 1.2em;
}

/* 16:9 video resolutions */
.video-container {
    height: 0;
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    @include breakpoint(medium,max) {
        margin-bottom: 1.5rem;
    }

    iframe {
        border: none;
        height: 100%;
        position: absolute;
        width: 100%;
    }
}

.lightbox-inline {

}

.lightbox-external {

}

/* default colorbox hover */
.lightbox-image, a[data-rel^="lightbox:"] {
    @extend .margin-bottom;
    display:table;
    width:100%;
    position:relative;
    text-decoration:none;

    &[href*=qr] {
        width:auto;

        &:before,
        &:after {
            content:none;
        }
    }

    &:before, &:after {
        @extend %animated-transform;
        pointer-events:none;
    }

    &:before {
        font-family: $icon-font2;
        content:'\ea16';
        align-items:center;
        border-radius:50% 0 0 0;
        bottom:1rem;
        color:rgba($light, .95); 
        display:flex;
        font-size:1rem;
        height:2rem;
        justify-content:center;
        position:absolute;
        right:1rem;
        text-align:center; 
        transform:translate3d(0, 0, 0);
        width:2rem;
        z-index:1;
        opacity: 0;
        @include breakpoint(giant) {
            font-size: rem(24px);
            bottom:.5rem;
            right:.5rem;
        }
    }
    &:after {
        position: absolute; 
        top:0;
        left:0; 
        bottom:0;
        right:0;
        content:'';
        z-index: -1;  
        .cmsimgBox & {
            box-shadow: none;
        }
    } 
    img {
        border:none;
        padding:10%;
        background: $light;
        border: 2px solid rgba($primary,.5);
        border-radius: $base-radius;
    }

    &:hover, &:focus {
        &:before {
            border-radius:0;
            height:100%;
            width:100%;
            background-color:rgba($primary, .5);
            font-size:2rem;
            bottom:0;
            right:0;
            border-radius: $base-radius;
            opacity: 1;
        }
    }
}


body {
    &:before {
        $content: 'default:' + $bp-context;
        width: str_length($content) * 6pt + $base-gap * 2;

        @each $point, $width in $breakpoints {
            $content: $content + '....' + $point + ':' + $width;

            @include breakpoint($point) {
                width: str_length($content) * 6pt + $base-gap * 2;
            }
        }

        content: '#{$content}';
        display: none !important; /* Prevent from displaying. */
    }

    &.devmode:before, &.debug:before {
        background: $alert;
        border-color: $alert;
        border-style: solid;
        border-width: 0 rem($base-gap);
        color: $light;
        display: block !important;
        font-family: Courier;
        font-size: 10pt;
        left: 0;
        line-height: 2.5;
        overflow: hidden;
        position: absolute;
        right: 0;
        text-align: center;
        top: 0;
        white-space: nowrap;
        z-index: 99999;
    }
}

// Animations
%animated-transform {
    transition: .3s;
}