// Use this file for your custom mixins/functions


//MIXINS
@mixin section {
	padding-top:rem(30px);
	padding-bottom:rem(30px);
	@include breakpoint(small) {
		padding-top:rem(50px);
		padding-bottom:rem(50px);  
	}
	@include breakpoint(medium) {
		padding-top:rem(60px);
		padding-bottom:rem(60px);
	}
	@include breakpoint(giant) {
		padding-top:rem(100px);
		padding-bottom:rem(100px);
	}
}

