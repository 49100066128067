// PAGE WRAP
//////////////////////////////

.page-wrap {
	padding-top: rem($menu-bar);
	
	@include breakpoint(giant) {
		padding-top: 0;
	} 
}

// HEADER
//////////////////////////////

.header {
	position: relative;
	padding: 0;
	width: 100%; 
	@include breakpoint(giant) {
		border-width: rem(5px);
	}
	body:not(.index) & {
		min-height: rem(170px);
		border-bottom: none;
		@include breakpoint(giant) {
			min-height: rem(220px);
		}
	}
	.top-bar {
		position: relative;
		@include breakpoint(giant) {
			padding-bottom:rem(77px);
		}
		
		&:before {
			@extend .before-after;			
			background: $light image-url('layout/bg-topbar.jpg') left bottom no-repeat;
			background-size: cover;
			position: absolute; 
			top: 0;
			bottom: 0;
			left: calc(50% - 50vw);
			right: calc(50% - 50vw);
			height: 100%;
			z-index: -1; 
		}
	}
	.borderBottom {
		@include breakpoint(small,max) {
			border-bottom:3px solid $light;
		}
	}
	.openingHours, .mail-box ,.phone-box {
		position: relative;
		padding: rem(20px) 0; 
		color: $dark;

		@include breakpoint(large) {
			line-height: rem(50px);
			font-size: rem(18px);			
		}
		@include breakpoint(huge) {
			font-size: rem(20px);
			font-weight: 300;	
		}
		@include breakpoint(monster) {
			font-size: rem(22px);
		}
	}
	.facebook {
		padding-left: rem($base-gap);
		padding-right: rem($base-gap);
		transition: 300ms;
		position: relative;
		top:-4px;
		img {
			width: rem(20px);
			height: rem(20px);
			@include breakpoint(giant) {
				width: rem(26px);
				height: rem(26px);
			}
		}
		&:hover {
			opacity: .5; 
			transition: 300ms;
		}
	}
	i {
		position: relative;
		font-size: 1.15em;
		margin-right: .3em;
		vertical-align: middle;
		left:rem(6px);
		top:-1px;

		&.fa-phone-square {
			transform: scaleX(-1);
		}
	}
	.mail-box {				
		display: none;
		
		@include breakpoint(large) {			
			display: block;						
			padding-right: $base-gap;
		}

		a {
			color: $dark;
			text-decoration: none;
			&:hover {
				color: $primary;
			}
		}
	}
	.phone-box {
		display: none;
		.phone {
			text-decoration: none;	
		}

		@include breakpoint(giant) {
			display: block;
			a {
				color: $dark;
			}
		}
	}
	.branding {
		width: rem(260px);
		height: auto;
		display: table;
		margin: rem(20px) auto;
		@include breakpoint(small) {
			margin-left: 0;
			width: rem(280px);
			margin-right: 0;
		}
		@include breakpoint(medium) {
			width: rem(387px);
		}
	    img {         
	        width:100%; 
	    }
	}
	.image-box {
		background: $medium image-url('layout/bg-header-default.jpg') no-repeat top center;
		background-size: cover;
		padding-top: rem(30px);
		padding-bottom: rem(30px);
		color: $primary;
		text-align: center;
		@include breakpoint(small) {			
			padding-top: rem(50px);
			padding-bottom: rem(50px);
		}
		@include breakpoint(large) {
			background-image: image-url('layout/bg-header.jpg');
			padding-top: rem(100px);
			padding-bottom: rem(100px);
		}
		@include breakpoint(huge) {			
			padding-top: rem(150px);
			padding-bottom: rem(150px);
		}
		.claim {
			@include breakpoint(medium,max) {
				margin-bottom: 1rem;
			}
			@include breakpoint(tiny) {
				font-size: rem(18px);
				line-height: 130%;
			}
			@include breakpoint(giant) {
				font-size: rem(22px);
			}
			@include breakpoint(huge) {
				font-size: rem(28px);
			}
			ul {
				border-bottom: $base-border;
				padding-bottom: rem(20px);
				margin-bottom: rem(20px);
			}
			p {
				margin-bottom: 0;
			}
		}
		.imgContainer {
			display: flex;
			align-items: center;
		}
		a {
			position: relative;
			text-decoration: none;
			&:before {
				content:'\eacc';	
				font-family: $icon-font2;
				display: flex;
				align-items: center;
				justify-content: center;
				position: absolute;
				bottom: 5%;
				right: 5%;
				color: $light;
				background: $secondary;
				width: rem(50px);
				height: rem(50px);
				border-radius: 50%;
				font-size: rem(30px);
				line-height: 100%;
				transition: 300ms;
				@include breakpoint(tiny) {
					width: rem(80px);
					font-size: rem(50px);
					height: rem(80px);
				}
			}
			&:hover {
				&:before {
					background: $primary;
					width: rem(60px);
					height: rem(60px);
					transition: 300ms;
					@include breakpoint(tiny) {
						width: rem(120px);
						height: rem(120px);
					}
				}
			}
		}
	}	
}

// MAIN CONTENT
//////////////////////////////
 
main {
	display: block;
	color: $darklight;

	ul, dl {
		@extend .styled-list;
	}

	b, strong {
		color: $primary;
	}

	.google-maps {
		@extend .video-container;
		.nojs & {
			display: none;
		}
		@include breakpoint(medium) {
			height: rem(400px);
		}
	}
}

// FOOTER
//////////////////////////////

.footer {
	display: block;
	background: $light;
	@include section;
	padding-top: 0!important;
	border-top: 10px solid $primary;
	@include breakpoint(monster) {
		font-size: rem(18px);
	}
	.google-maps {
		background: $primary;
		.nojs & {
			display: none;
		}
		iframe {
			opacity: .7;
			min-height: rem(300px);
			display: block;
		}
	}
	.headline {
		font-weight: normal;
		text-transform: none;
		margin-bottom: 2rem;
		font-size: rem(22px);
		line-height: 120%;
		border:none;
		text-align: left;
		color: $dark;
		margin-top:rem(30px);
		@include breakpoint(small) {
			margin-top:rem(50px);
		}
		@include breakpoint(medium) {
			margin-top:rem(60px);
			font-size: rem(26px);
		}
		@include breakpoint(giant) {
			margin-top:rem(100px);
			font-size: rem(30px); 
		}
	}
	> span {
		display: block;
	}
	a {
		color: $dark;
		text-decoration: none; 
		&:hover {
			text-decoration: underline;
		}
	}
	b, strong {
		color: $primary;
	}
	.partner {
		@include breakpoint(small) {
			text-align: right;
		}
		@include breakpoint(large) {
			text-align: center;
		}
	}
}


// MISC
//////////////////////////////
.section {
	@include section; 
}
.section {
	position: relative;
    body:not(.cmsbackend) & {
		z-index: 1;
    }
	&:before {
		content: "";
		background-color: $light;
		position: absolute;
		top: 0;
		bottom: 0;
		left: calc(50% - 50vw);
		right: calc(50% - 50vw);
		z-index: -1;
	}
	&.color {
		&:before {
			background-color: $medium;
		}
	}
	&.dark {
		&:before {
			background: $primarydark image-url('layout/bg-dark.png') top center no-repeat;
			background-size: 100% auto;
		}
	}
	&.noGap {
		padding-top:0;
	}
	&.noGapBottom {
		padding-bottom:0; 
	}
	&:nth-child(odd) {
		@extend .color;
	}
}

// Mitwachsende Box für .col
body:not(.cmsbackend) .contentBox {
    z-index: 1;
    position: relative;
    padding:rem($base-gap*2); 
    margin:rem($base-gap*2) 0;
    margin-top: 0;
    @include breakpoint(large) {
    	padding:rem($base-gap*3) rem($base-gap*4);
    }
    > *:last-child {
    	margin-bottom: 0; 
    }
    &:before {
        content:'';
        background: rgba($light,.9);
        position: absolute;
        top:0;
        left:0;
        right:0;
        margin:auto;
        width: calc(100% - #{rem($base-gap*2)}); 
        height: 100%;
        z-index: -1;
        box-shadow: $base-shadow;
        border-radius: $base-radius;
    }
}
body:not(.cmsbackend) .contentBox2 {
    z-index: 1;
    position: relative;
    padding:rem($base-gap*2); 
    margin:rem($base-gap*2) 0;
    margin-top: 0;
    margin-bottom: 0;
    @include breakpoint(large) {
    	padding:rem($base-gap*3) rem($base-gap*4);
    }
    > *:last-child {
    	margin-bottom: 0; 
    }
    &:before {
        content:'';
        background: rgba($light,.9);
        position: absolute;
        top:0;
        left:0;
        right:0;
        margin:auto;
        width: calc(100% - #{rem($base-gap*2)}); 
        height: 100%;
        z-index: -1;
        border-radius: $base-radius;
        border: 2px solid rgba($primary,.6);
    }
}
 
// CMS IMG 
body:not(.cmsbackend) .cmsimgBox {
	display: flex; 
	flex-wrap:wrap;
	justify-content:space-between;
	align-items:stretch;
	margin: 0 rem(-4px) rem(30px) rem(-4px);
	> div {
		flex:1 0 30%;
		margin:rem(4px); 
		display: flex;
		align-items:center;
		position: relative;
		.lightbox-image {
			margin-bottom: 0;
		}
	}
}

//Öffnungszeiten
.opening-hours {
    position: relative;
    label {
        padding-left: rem(30px)!important;
        &:before {
            content: '\f017';
            font-family: $icon-font;
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            font-size: 1em;
        }
        &:hover {
            cursor: pointer;
            color:$primary;
        } 
    }
    .openingHoursContent {
        opacity: 0;
        visibility: hidden;
        display: none;
        position: absolute;
        top: 120%;
        left: 50%;
        background: $light;
        color: $dark;
        padding: 1em .5em 2em .5em;	
        z-index: 10;
        width: rem(230px);
        border-radius: rem(10px);
        display: block;
        transform:translateX(-50%);
        box-shadow: $base-shadow;
        @include breakpoint(small) {
        	width: rem(360px);
        }
        @include breakpoint(giant) {
        	width: rem(440px);
        	padding: 1em 2em 2em 2em;	
        }
        div {
        	font-size: rem(14px);
        	line-height: 150%;
        	@include breakpoint(giant) {
        		font-size: rem(18px);
        	}
        	i {
        		display: block;
        		width: rem(70px);
        		color:$dark;
        		font-style: normal;
        		font-size: 1em;
        		font-weight: bold;
        		@include breakpoint(small) {
        			display: inline-block;
        			font-weight: normal;
        		}
        	}
        }
    }
}
// toggler
#openingHours {
    display: none;
}
#openingHours:checked {
    & ~ .opening-hours .openingHoursContent {
        display: block;
        transition: 300ms;
        opacity: 1;
        visibility: visible;
    } 
    & ~ .opening-hours label {
        color:$primary;
        &:before {
        	font-weight: 900;
            content: '\f00d'!important;
        }
    }
}
.width {
    display: inline-block;
    min-width: rem(100px);
}
.openingHoursText {
	strong {
		font-weight: bold;
		color: $primary;
		border-bottom: $base-border;
		display: block;
		margin-top: 1em;
	}
	p {
		text-align: center;
		margin-bottom: 0;
	}
}


// PARTNERLOGOS
.logo {
  display: flex;
  width: 100%;
  min-width: 100%;
  flex-wrap:wrap;
  justify-content:center;
  background:$light;
  .partnerBox & {
  	background: none;
  	margin-top: rem(30px);
  	.logoBox {
  		box-shadow: none;
  		background: darken($primarydark,10%);
  		transition: 300ms;
  		img {
  			filter: grayscale(100%);
	  		transition: 300ms;
	  		mix-blend-mode: multiply;
  		}
  		&:hover {
  			background: white;
  			transition: 300ms;
  			img {
  				filter: grayscale(0);
  				transition: 300ms;
  			}
  		}
  	}
  }
  .logoBox {
    width: rem(200px);
    max-width: 100%;
    box-shadow: $base-shadow;
  	border-radius: $base-radius;
    display: flex;
    justify-content:center;
    align-items:flex-end;
    margin:.5rem;
    padding:.5rem;
    flex-wrap:wrap;
    position: relative;
    text-align: center;
    @include breakpoint(giant) {
      margin:1rem;
      padding:1rem;
    }
    > *:first-child {
      align-self: center; 
    }
    > img {
      max-width: 90%;
      width: auto;
      height: auto;
    }
    a {
      body:not(.cmsbackend) &:before {
        content:'\e9b5';
        font-family: $icon-font2;
        background: $primary;
        color:$light;
        position: absolute;
        top:0;
        right:0;
        font-size:rem(20px);
        padding:rem(5px);
        border-radius:0 0 0 10px;
        transition:300ms background, 300ms font-size, 300ms padding;
      }
      body:not(.cmsbackend) &:after {
        content:'';
        position: absolute;
        top:0;
        left:0;
        right:0;
        bottom:0;
      }
      &:hover {
        body:not(.cmsbackend) &:before {
          background: $secondary;
          transition:300ms background, 300ms font-size, 300ms padding;
          padding:rem(10px);
          font-size:rem(24px);
        }
      }
    }
    span {
      padding:rem(5px) 0;
      font-size: 0.875em;
    }
  }
}
