// Main Navigation
//////////////////////////////
.navi-main {
	@include breakpoint(giant) {
		display: flex;
		justify-content: space-between;
		align-items: center;		
		height: rem(94px);		
		width: calc(100% - #{$base-gap}*2);
		margin: 0 auto;
	}	
	@include breakpoint(huge) {
		max-width: calc(#{rem($row-max-width)} - #{rem($base-gap)} * 2);
		height: rem(90px);
	}

	li {
		position: relative;
		border-bottom: solid rem(1px) rgba($dark,.15);
		padding: 1em $base-gap;
		&:last-child {
			border:none;
		}
		@include breakpoint(giant) {
			padding: 0;
			border: none;			
		}
		&.impressum, 
		&.datenschutzerklaerung		
		{			
			@include breakpoint(giant) {
				display: none;
			}
		}
		&.onlineshop {
			@include breakpoint(giant,max) {
				a {
					color: $secondary;
					font-weight: bold;
					&:hover, &.active {
						color: $primary;
					}
				}
			}
			@include breakpoint(giant) {
				order: 2;
				a {
					background: darken($secondary,5%);
					border-radius: $base-radius;
					padding-left: 1em;
					padding-right: 1em;
					transition: 300ms;
					text-decoration: none;	
					&:hover {
						background: darken($secondary,15%);
						transition: 300ms;
						text-decoration: none;	
					}
				}
			}
		}
		a, span {
			position: relative;
			color: $dark;
			text-decoration: none;
			text-transform: uppercase;
			font-family: $display-font;	
			transition: color 300ms ease-in-out;
			display: block;
			font-weight: 300;
			padding:0 rem($base-gap);
			letter-spacing: 2%;
			@include breakpoint(giant) {
				position: relative;
				color: $light;
				font-size: rem(18px);
				padding:rem(10px) 0;
				text-transform: none;
				letter-spacing: 0;
			}
			@include breakpoint(huge) {				
				font-size: rem(22px);				
			}
			i {
				font-size: rem(30px);
				vertical-align: middle;
			}

			&:hover {
				color: $primary;
				@include breakpoint(giant) {
					color: rgba($light,.9);
					text-decoration: underline;
				}
			}			
		}
				
		.active {
			color: $primary;
			font-weight: bold;
			@include breakpoint(giant) {
				text-decoration: underline;
				font-weight: normal;
				color: rgba($light,.9);
			}
		}		
	}
}

// Mobile Menu Bar
//////////////////////////////
$menu-bar: 50px;
.page-navi {
	position: relative;
	// Off Canvas Navigation
	//////////////////////////////	
	@include breakpoint(giant,max) {
		background: $light;
		left:-100%;
		overflow-y: auto;
		position: fixed;
		top:rem($menu-bar);
		transition:.6s;
		width:map-get($breakpoints, tiny) * .8;
		z-index: 895;
	    bottom: 0;
	    max-width:100%;
	}
	// Desktop Navigation
	//////////////////////////////
	@include breakpoint(giant) {
		background: $secondary;
		position: absolute;
		top: rem(152px);
		left: 0;
		z-index: 3;
		width: 100%;
		transition: background 250ms ease-in-out;	

		body.scrolled & {			
			background:rgba($primary,1);
			position: fixed;
			top: 0;			
		}
	}
}

.mobile-bar {
	align-items:center;
	background:$primary;
	display: flex;
	height:rem($menu-bar);
	justify-content:stretch;
	left:0;
	position: fixed;
	right:0;
	top:0;
	z-index: 894;
	font-family: $display-font;
	padding-top: rem(20px);
	border-bottom:1px solid rgba($light,.2);
	padding-bottom: rem(20px);
	font-size: 	rem(13px);
	@include breakpoint(giant) {
		display: none;
	}	
	li {
		display:flex;
		padding:0 rem($base-gap*.5);
		color: $light;
		@include breakpoint(tiny) {
			padding:0 rem($base-gap);
		}
		&:last-child {
			flex-grow:1;
            justify-content:flex-end;
        }
        a {
        	color: $light;
        	text-decoration: none;
        }
	}
}

label[for="navi-toggled"] {
	@extend .fa-bars;
	flex-direction:row-reverse;
	font-size: rem(18px);
	font-family: $display-font!important;
	font-weight: 400!important;
	text-transform: uppercase;
	opacity: .7;

	&:before {
		@extend .fa-solid;
		margin-right: rem(10px);
		font-size: rem(18px);
	}
	&:after {
		background:rgba($dark,0.8);
		bottom:0;
		content:'';
		left:0;
		opacity: 0;
		pointer-events:none;
		position: fixed;
		top:rem($menu-bar);
		transition:opacity .6s;
		width: 100%;
		z-index:-1;
		visibility: hidden;
	}
}

#navi-toggled {
	display: none;
	&:checked {
		~ * label[for="navi-toggled"] {
			@extend .fa-solid;
			@extend .fa-times;

			&:after {
				opacity: 1;
				pointer-events:auto;
				visibility: visible;
			}
		}
		~ * .page-navi,
		~ .page-navi {			
			left:0;
		}	
		~ .page-wrap .booking-teaser {
			display: none;
		}	
	}
}

.naviBox {
	align-self: flex-end;
	.navi-add {
		li {
			text-align: center;
			a {
				text-decoration: none;	
				padding: rem(10px) 0;
				display: inline-block;
				color: $dark;
				&:hover, &.active {
					color: $primary;
				}
			}
		}
		@include breakpoint(large,max) {
			border-top: 1px solid rgba($dark,.1);
			margin-top: rem(30px);
			padding-bottom: rem(50px); 
			padding-top: rem(30px); 
			li {
				flex: 1 0 100%;
			}
		}
		@include breakpoint(large) {
			display: flex;
			flex-direction: row;
			justify-content: center;
			li {
				// flex: 0 0 auto;
				display: flex;
				&:before {
					content: '';
					display: block;
					height: 100%;
					width: 1px;
					background: rgba($dark,.1);
					float: left;
					margin: 0 1em;
				}
				&:first-child:before {
					display: none;
				}
				a {
					padding: 0;
				}
			}
		}
		@include breakpoint(large) {
			justify-content: flex-end;
		}
	}
}
